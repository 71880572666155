:root{
    --primary : #6610f2;
}
body{
    font-family: 'Open Sans', sans-serif;
}
.secondary-family{
    font-family: 'Roboto', sans-serif;
}
ul{
    margin:0;
    padding:0;
}
/* .line-height-0{
    line-height: 0 !important;
} */
/* colors */
.ckn-text-primary{
    color: var(--primary);
}
/* --colors-- */
.mb-10{
    margin-bottom:10px !important;
}
.mb-15{
    margin-bottom: 15px !important;
}
.mt-30{
    margin-top:30px;
}
.mt-60{
    margin-top: 60px;
}
.mt-56{
    margin-top:56px;
}
.mt-10{
margin-top:10px;
}
.mt-100{
    margin-top: 100px;
}
.ml-20{
    margin-left:20px !important;
}
.ml-72{
    margin-left:72px;
}
.ml-200{
    margin-left: 200px;
}
.ml-10{
    margin-left: 10px !important;
}
.ml-4{
    margin-left: 4px;
}
.mr-5{
    margin-right: 5px;
}
.ml-auto{
    margin-left: auto !important;
}
.pr-20{
    padding-right: 20px !important;
}
.pl-20{
    padding-left:20px !important;
}
.pl-30{
    padding-left: 30px;
}
.pr-30{
    padding-right: 30px;
}
.pl-45{
    padding-left: 45px;
}
.min-h-0{
    min-height: 0!important;
}
.min-h600{
    min-height: 600px;
}
.max-h160{
    max-height: 160px;
}
.h-56{
    height:56px;
}
.h-36{
    height:36px;
}
.f-s11{
    font-size: 11px;
}
.f-s12{
    font-size: 12px !important;
}
.f-s13{
    font-size:13px !important;
}
.f-s14{
    font-size:14px !important;
}
.f-s15{
    font-size: 15px !important;
}
.f-s16{
    font-size: 16px !important;
}
.f-s20{
    font-size:20px !important;
}
/* .f-s28{
    font-size:28px !important;
} */
.f-w-bold{
    font-weight: bold;
}
.f-w-600{
    font-weight: 600 !important;
}
.header{
    height: 45px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: var(--primary);
}
.main-content{
    margin-top: 45px;
}
.nav-bar{
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 100;
}
.nav-normal-screen{
    width:200px;
}
.nav-normal-screen a{ 
    padding:10px 26px !important;
    display: flex;
    align-items: center;
}
.nav-normal-screen i{
    display:inline-block;
}
.nav-normal-screen span{
    display: inline-block;
    margin-left: 25px;
    font-size: 13px;
}
.nav-large-screen{
    width: 200px;
}
.nav-large-screen a{ 
    padding:10px 26px !important;
    display: flex;
    align-items: center;
}
.nav-large-screen i{
    display:inline-block
}
.nav-large-screen span{
    display: inline-block;
    margin-left: 25px;
    font-size: 13px;
}
.nav-normal-screen-sm{
    width:250px;
    z-index: 100;
}
.nav-normal-screen-sm .nav-link{ 
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
}
.nav-normal-screen-sm i{
    display:inline-block
}
.nav-normal-screen-sm span{
    display: inline-block;
    margin-left: 1rem;
    font-size: 13px;
}

.nav-bar-overlay{
    background-color: rgba(0, 0, 0, 0.5);
    left:0;
    right: 0;
}
.nav-bar-header{
    height: 45px;
}
.alert-icon{
    font-size: 28px;
}
.logo{
    padding-left: 15px;
    padding-right: 20px;
    font-weight: 400;
    font-size: 22px;
    display: inline-block;
}
.screen-control-btn{
    font-size: 24px;
    padding: 0;
}
.search-bar{
    width: 200px;
    font-size: 13px;
}
.search-bar-icon{
    position: absolute;
    top : 7px;
    left: 10px;
    font-size: 14px;
}
.form-control__control{
    min-height: initial !important; 
}   
.form-control__control--is-focused {
    border-width: 1px !important;
    min-height: initial;    
    border-color: #6610f2 !important; 
    box-shadow: 0 0 0 1px #6610f2 !important;
}
.form-control__indicator{
    padding: 0 5px !important
}
.search-bar__control{
    border-radius: 0 !important;
    min-height: initial !important;
}
.large-icon {
    font-size: 28px !important;
}
.z-index100{
    z-index: 100;
}
.pac-container {
    z-index: 9999999;
}
.sort-element{
    z-index: 9999999;
}
.nav-tabs .nav-link{
    border-top:3px solid transparent !important;
    border-bottom:3px solid transparent !important;
}
.nav-tabs .nav-link.active{
    border-color: transparent transparent #6610f2 !important;
    font-weight: 600;
    color: #6610f2;
    background: #fff !important;
    box-shadow: rgba(0, 0, 0, 0.05);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: transparent; 
   isolation: isolate;
}
.tab{
    font-size: 12px;
}
.border-right{
    border-right:1px solid #dee2e6;
}
.label{
    font-weight: 600;
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.7px;
}
.valid-input  {

    display: inline-block;
    vertical-align: -.125em;
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' width='16' height='16'  class='bi bi-check-lg ' fill='%23198754' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z' clip-rule='evenodd'/></svg>");
    border-color: #198754;
    padding-right: calc(1.5em + .75rem);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
      
} 

.modal-title{
    color: #0d0d0d;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.5px;
}
table tr th:first-child{
    padding-left: 30px !important;
}
table tr th:last-child{
    padding-right: 30px !important;
}
table tr td:first-child{
    padding-left: 30px !important;
}
table tr td:last-child{
    padding-right: 30px !important;
}
.table th{
    letter-spacing:0.50px;
    border-color: #dee2e6 !important;
    background: #cfcfcf;
    color: #1a1a1a;
}
.table td{
    letter-spacing: 0.2px;
}
.page-link{
    border:0;
    color:#606060;
    cursor: pointer;
}
.page-link:hover{
    color:#000;
}
.text-primary{
    color: #6610f2 !important;
}
.text-secondary{
    color:#606060 !important;
}
.form-control:focus{
    box-shadow: 0 0 0 1px #6610f2 !important;
   -webkit-box-shadow: 0 0 0 1px #6610f2 !important;
   border-color:#6610f2;   
}
.form-select{
    cursor: pointer;
}
.form-select:focus {
     border-color: #6610f2 !important; 
    outline: 0;
    -webkit-box-shadow:none;
    box-shadow: 0 0 0 1px #6610f2 !important;
}

.cursor-pointer {
    cursor: pointer;
}
.action button {
    margin : 0.25px
}
.modal{
    background-color: rgba(0,0,0,0.50);
}
.bg-primary{
    background: #6610f2 !important;
}
.border-primary{
    border-color: #6610f2 !important;
}
.btn{
    letter-spacing: 0.25px;
}
.btn:focus{
    -webkit-box-shadow:none;
    box-shadow: none;
}
.btn:focus:active{
    -webkit-box-shadow:none;
    box-shadow: none;
}
.btn-primary{
    background-color: #6610f2;
    border-color: #6610f2;
}

.btn-outline-primary{
    border-color: #6610f2;
    color: #6610f2;
}
.btn-outline-primary:hover{
    background-color: #6610f2;
    border-color: #6610f2;
}
.btn-link{
    text-decoration:none;
    padding:0 !important;
    line-height: 0;
}
.btn-link:hover{
    text-decoration:underline;
}
.nav-link{
    color:#606060;
}
.nav-link:hover{
    background-color: rgba(0, 0, 0, 0.05); 
    color: #6610f2; 
}
.navbar-nav .nav-link.active{
    background-color: rgba(0, 0, 0, 0.05); 
    color: #6610f2; 
    border-left: 6px solid #6610f2;
}
.nav-link:active{
    background-color: rgba(0,0,0,0.10);
    
}
.form-check-input:focus{
    --webkit-box-shadow : none;
    box-shadow: none;
}
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
/* .toggle-btn:hover{
background-color: transparent;
border-color: transparent;
outline-color:transparent;
}
.toggle-btn:focus{
    background: transparent;
    border-color: transparent;
    outline-color:transparent;
} */
/* border with cender text */
.line h6{
    text-align:center;
    position:relative; 
    }
.line h6 span {
    background-color: white;
    position: relative; 
    top: 10px; 
    padding: 0 10px;
}

.progressbar li{
    list-style-type:none;
    float:left;
    width:33.33%;
    text-align:center;
    font-weight:600;
    position: relative;
}
.progressbar{
    counter-reset: step;
}
.progressbar li:before{
content:counter(step);
counter-increment:step;
height: 30px;
width:30px;
border: 2px solid #ddd;
line-height: 30px;
border-radius: 50%;
display: block;
margin:0 auto 5px auto; 
background-color: white;
font-size: 14px;
}
.progressbar li::after{
content: "";
position: absolute;
width:100%;
height:2px;
background-color: #ddd;
top: 15px;
left: -50%;
z-index: -1;
}
.progressbar li:first-child:after {
content:none;
}
.progressbar li.active {
color:#6610f2;
}
.progressbar li.active:before {
border-color:#6610f2;
}
.progressbar li.active + li:after{
background-color:#6610f2;
}
.avatar {
    border-radius: 50% ;
    width:100%;
    height: auto;
    white-space: nowrap;
    overflow: hidden ;
}
.avatar img{
    border-radius: 50% ;
    max-width: 100% ;
    height: 100%;
}
.cropped-area {
   border : 2px dashed #a3afb7 ;
   height: 350px ; 
}
.avatar-lg {
    width :150px ;
    height : 150px ;
}
.image-cropper div:first-child{
    height: 100%;
}
.image-cropper img{
    height: 100%;
}

.map-autocomplete input{
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    width: 100%;
}

.map-autocomplete input:focus{
    border-color: #6610f2;
}

.map-autocomplete input:focus-visible{
    outline: 0 !important;
}

.timePicker {
    width: 50px;
    display: inline-block;
}

.page-title{
    font-weight: 600;
    line-height: 32px;
    white-space: nowrap;
}
.page-header{
    font-size: 17px;
}
.pagination-search{
    width: 50px !important;
    margin-right: 5px;
}
/* calender*/
.rbc-event-content{
    padding: 0 !important;
    font-size:12px;
}
.rbc-show-more{
    font-size: 13px !important;
}
.rbc-header{
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.rbc-date-cell{
    text-align: center !important;
}
.date-picker{
    width:210px;
    position: relative;
}
.date-picker i{
    position: absolute;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    top:0;
    right: 0;
    font-size: 18px;
}
.date-picker input{
    padding: 5px;
    font-size: 18px;
}
.form-check-custom{
    padding: 0 !important;
    font-size: 16px;
}
.form-check-custom input{
    margin-left: 0 !important;
    margin-right: 8px !important;
}
.draggble-column{
    height: 350px;
    overflow: auto;
}
.draggable{
    cursor: move;
}
.modal-content-custom{
    min-height: 450px;
}
/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.accordion-custom::before{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    content: '';
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: blue;
}
.mt-md-40 {
    margin-top: 40px;
}
.signup-bg-wrapper {
    position: relative;
}
.signup-bg-wrapper img {
    position: absolute;
    left: 230px;
    top: 100px;
    z-index: -1;
    width: auto;
}
.mt-70 {
    margin-top: 70px;
}
.signup-content {
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 0;
    margin-top: 50px;
}
.mandatory-input {
    font-size: 14px;
    margin-left: 2px;
}
.signup-content-highlight {
    font-weight: 700;
    margin: 0;
    font-size: 30px;
    color: #6610f2;
    text-align: center;
}
/* media quiry  */
@media only screen and (min-width:768px){
    .page-title{
        font-size: 23px;
        letter-spacing: -0.10px;
    }
    .tab{
        font-size: 14px;
    }
    .search-bar{
        width: 500px;
    }
    .mt-md-60{
        margin-top:60px;
    }
    .mt-md-100{
        margin-top:100px;
    }
    .border-md{
        border:1px solid #dee2e6 ;
    }
    .nav-normal-screen{
        width:72px;
        text-align:center;
    }
    .nav-normal-screen a{
        padding:10px 0 !important;
        display: block;
    }
    .nav-normal-screen i{
        display:block
    }
    .nav-normal-screen span{
        display: block;
        font-size: 9px;
        margin-left: 0;
    }
    .nav-large-screen{
        width:72px;
        text-align:center;
    }
    .nav-large-screen a{
        padding:10px 0 !important;
        display: block;
    }
    .nav-large-screen i{
        display:block
    }
    .nav-large-screen span{
        display: block;
        font-size: 9px;
        margin-left: 0;
    }
    .nav-bar-md{
        top: 45px;
    }
    .main-content-normal-screen{
        margin-left: 72px;
    }
    .main-content-large-screen{
        margin-left: 72px;
    }
    .nav-normal-screen-sm .nav-link{
        padding-left: 1.5rem;
    }
    .logo-container{
        width: 200px;
    }
}

@media (min-width: 992px){
    .nav-bar-overlay{
        background-color: transparent;
        left: auto;
        right: auto;
    }
    .main-content-large-screen{
        margin-left: 72px;
    }
    .main-content-normal-screen{
        margin-left: 200px;
    }
    .nav-normal-screen{
        width:200px;
    }
    .nav-normal-screen a{ 
        padding:10px 26px !important;
        display: flex;
        align-items: center;
    }
    .nav-normal-screen i{
        display:inline-block
    }
    .nav-normal-screen span{
        display: inline-block;
        margin-left: 25px;
        font-size: 13px;
    }
    .nav-large-screen{
        width:72px;
        text-align:center;
    }
    .nav-large-screen a{
        padding:10px 0 !important;
        display: block;
    }
    .nav-large-screen i{
        display:block
    }
    .nav-large-screen span{
        display: block;
        font-size: 9px;
        margin-left: 0;
    }
    
}


.accordion-button {
    padding: 0;
}

.change-shift-container {
    border: 1px solid #e1e1e1;
    margin-top: 20px;
    padding: 15px;
}

.change-shift-title {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
}

.react-datepicker-wrapper input[type="text"] {
    width: 100%;
    padding: 0.375rem 0.75rem;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
}

.react-datepicker-wrapper input[type="text"]:focus-visible {
    border: 1px solid #6610f2;
    outline: 1px solid #6610f2;
}

.list-group, .list-group-item {
    border: 0;
}

.to-do-wrapper {
    border: 0;
    box-shadow: 4px 4px 10px 3px #e9e9e9;
    border-radius: 0;
}